// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Paragraph />
type Props = {
  /** Root element tag */
  tag: string,
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Generic paragraph
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .paragraph       | Root element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/paragraph.scss
 * - find paragraph @mixin at /app/core/styles/mixins/_paragraph.scss
 */
class Paragraph extends React.Component<Props> {
  static defaultProps = {
    tag: "p",
  }

  // react methods

  render() {
    let {
      // element props
      tag: ParagraphTag,
      // class names
      className,
      // content
      children,
      // passthru
      ...paragraphProps
    } = this.props

    // return paragraph
    return (
      <ParagraphTag
        {...classNames("paragraph").plus(className)}
        {...paragraphProps}
      >
        {children}
      </ParagraphTag>
    )
  }
}

/**
 * Exports
 */
export default Paragraph
