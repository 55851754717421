// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/props"

// components
import Row from "../../core/components/grid/row"
import withGSAPBulkAnimation from "../../core/animations/gsap-bulk"

/**
 * Animate map
 */

const LoaderBody = withProps(
  withGSAPBulkAnimation(Row, {
    onStart: () => {
      document.body.style.opacity = 1
    },
    autoplay: true,
    loop: false,
  }),
  { id: "loader-body" }
)

/**
 * Exports
 */
export default LoaderBody
