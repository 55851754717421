// @flow
import * as React from "react"
import classNames from "react-css-module-classnames"

// <DescriptionList />
type Props = {
  /** Component data, perhaps provided by withData() */
  data: {
    /** Array of list items */
    items: Array<{
      /** Custom thumbnail element */
      title: React.Node | Element,
      /** List item string content */
      content: React.Node | string,
      /** List item html (if set, "content" is ignored) */
      html: React.Node | string,
      /** Custom class name */
      className?: string,
      ...
    }>,
  },
  /** Custom class for list element */
  listClassName?: string,
  /** Custom class for list item element */
  itemClassName?: string,
  /** Custom class for title element */
  titleClassName?: string,
  /** Custom class for content element */
  contentClassName?: string,
  /** Root element class name */
  className?: string,
  ...
}

/**
 * General <dl> list element.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .list            | Root element (not unique, select with dl.list)         |
 * | .item            | List item element                                      |
 * | .title           | List item title element                                |
 * | .content         | List item content element                              |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/description-list.scss
 */
class DescriptionList extends React.Component<Props> {
  static defaultProps = {
    data: {
      definitions: {},
    },
  }

  // react methods

  render() {
    const {
      // element data
      data,
      // class names
      listClassName,
      itemClassName,
      titleClassName,
      contentClassName,
      className,
      // passthru
      ...listProps
    } = this.props

    return (
      <dl
        {...classNames("list")
          .plus(className)
          .plus(listClassName)}
      >
        {data.items.map(({ title, className, content: children, html }, i) => {
          const ddProps = {}

          if (html) {
            Object.assign(ddProps, {
              dangerouslySetInnerHTML: { __html: html },
            })
          } else {
            Object.assign(ddProps, { children })
          }

          return (
            <div
              key={i}
              {...classNames("item")
                .plus(itemClassName)
                .plus(className)}
            >
              <dt {...classNames("title").plus(titleClassName)}>{title}</dt>
              <dd
                {...classNames("content").plus(contentClassName)}
                {...ddProps}
              />
            </div>
          )
        })}
      </dl>
    )
  }
}

/**
 * Exports
 */
export default DescriptionList
