// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <GeneratedBlock />
type Props = {
  /** Component data, perhaps provided by withData() */
  data: {
    /** Array of elements for the block to contain */
    elements: Array<React.Node | Element>,
  },
  /** Root element tag */
  tag: string,
  /** Custom class for root element */
  className?: string,
  ...
}

/**
 * Generated content block on the css grid. Blocks are contained in Rows.
 *
 * Allows for easy mockups of custom content blocks generated from data.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .block           | Root element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/block.scss
 * - find block @mixin at /app/core/styles/mixins/_block.scss
 * - find grid styles at /app/client/styles/placeholders/grid.scss
 */
class GeneratedBlock extends React.Component<Props> {
  static defaultProps = {
    tag: "div",
  }

  // react methods

  render() {
    const {
      tag: GeneratedBlockTag,
      className,
      data,
      ...blockProps
    } = this.props

    return (
      <GeneratedBlockTag
        {...classNames("block").plus(className)}
        {...blockProps}
      >
        {data.elements}
      </GeneratedBlockTag>
    )
  }
}

/**
 * Exports
 */
export default GeneratedBlock
