// @flow

// react
import * as React from "react"
import ReactDOM from "react-dom"
import classNames from "react-css-module-classnames"

// scroll lock
import { disablePageScroll, enablePageScroll } from "scroll-lock"

// animation
import gsap from "gsap"

// utility
import isMobile from "../../utility/is-mobile"

// <Modal />
type Props = {
  /** Enable modal backdrop */
  backdrop: boolean,
  /** Enable modal close button: use non-boolean for custom content */
  closeButton: boolean | React.Node | Element,
  /** Animation duration (seconds) */
  duration: number,
  /**
   * ## Animation timeline functions
   *
   * For convenience, each function recieves a single argument, an object with
   * the modal elements and props.
   */
  timelines: {
    /** Handles animation initial state; returns a cleanup function */
    initialState: (args: {
      self?: Element | null,
      backdrop?: Element | null,
      closeButton?: Element | null,
      content?: Element | null,
    }) => Function | false,
    /** Handles animation in; returns a cleanup function */
    in: (args: {
      self?: Element | null,
      backdrop?: Element | null,
      closeButton?: Element | null,
      content?: Element | null,
      duration: number,
    }) => Function | false,
    /** Handles animation out; returns a cleanup function */
    out: (args: {
      self?: Element | null,
      backdrop?: Element | null,
      closeButton?: Element | null,
      content?: Element | null,
      duration: number,
    }) => Function | false,
  },
  /** Whether modal should lock scroll */
  scrollLock:
    | {
        lock: "start" | "end",
        unlock: "start" | "end",
      }
    | false,
  /** Root element class name */
  className?: string,
  /** Modal custom class name */
  modalClassName?: string,
  /** Backdrop custom class name */
  backdropClassName?: string,
  /** Close Button custom class name */
  closeButtonClassName?: string,
  /** Content custom class name */
  contentClassName?: string,
  /** onChange handler */
  onChange?: Function,
  /** Content */
  children: React.Node,
  ...
}

type State = {
  /** Active: true if button is active */
  active: boolean,
}

/**
 * Timeline Functions
 */

/** Set opacity of backdrop and content to zero */
const hide = ({
  self,
  backdrop,
  content,
  opts,
}: {
  self?: Element | null,
  backdrop?: Element | null,
  content?: Element | null,
  opts?: { ... },
}) => {
  const timeline = gsap.timeline(opts).to(
    [backdrop, content].filter(el => el),
    { duration: 0, opacity: 0 }
  )

  return () => timeline.clear().kill()
}

/** Show self and animate opacity of backdrop and content to 1 */
const fadeIn = ({
  self,
  backdrop,
  content,
  duration,
  opts,
}: {
  self?: Element | null,
  backdrop?: Element | null,
  content?: Element | null,
  duration: number,
  opts?: { ... },
}) => {
  const timeline = gsap
    .timeline(opts)
    .set(self, { display: "block" })
    .to(
      [self, backdrop, content].filter(el => el),
      {
        duration,
        opacity: 1,
      }
    )

  return () => timeline.clear().kill()
}

/** Animate opacity of backdrop and content to 1 and hide self */
const fadeOut = ({
  self,
  backdrop,
  content,
  duration,
  opts,
}: {
  self?: Element | null,
  backdrop?: Element | null,
  content?: Element | null,
  duration: number,
  opts?: { ... },
}) => {
  const timeline = gsap
    .timeline(opts)
    .to(
      [backdrop, content].filter(el => el),
      { duration, opacity: 0 }
    )
    .to(self, { display: "none" })

  return () => timeline.clear().kill()
}

/**
 * Animated modal element.
 *
 * Create a modal element with optional backdrop and close button.
 *
 * Animation is handled by GSAP by default, but can be overridden with the
 * "timelines" prop.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .modal           | Root element                                           |
 * | .backdrop        | Backdrop element                                       |
 * | .content         | Content element                                        |
 * | .close_button    | Close button element                                   |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/modal.scss
 */
class Modal extends React.Component<Props, State> {
  static defaultProps = {
    backdrop: false,
    closeButton: "✖",
    duration: 0.5,
    timelines: {
      initialState: hide,
      in: fadeIn,
      out: fadeOut,
    },
    scrollLock: false,
  }

  state = {
    active: false,
  }

  // static methods
  static hide: Function
  static fadeIn: Function
  static fadeOut: Function

  // overwritten methods
  cleanUpTimeline: Function | false

  // refs
  self: Element | null
  backdrop: Element | null
  closeButton: Element | null
  content: Element | null

  // custom methods

  /** Open modal window */
  open() {
    this.setState({ active: true })
  }

  /** Close modal window */
  close() {
    this.setState({ active: false })
  }

  /** Toggle modal window */
  toggle() {
    this.setState({ active: !this.state.active })
  }

  /** Set modal animation timeline  */
  setTimeline(timelineKey: "initialState" | "in" | "out") {
    let { timelines, duration, scrollLock } = this.props

    // get node
    const node = this.self
    if (!node) return

    // get other nodes
    const backdrop = this.backdrop
    const closeButton = this.closeButton
    const content = this.content

    // get timeline function
    timelines = Object.assign({}, Modal.defaultProps.timelines, timelines)
    const timeline = timelines[timelineKey]
    if (typeof timeline !== "function") return

    // run clean up handler if one exists
    if (typeof this.cleanUpTimeline === "function") {
      this.cleanUpTimeline()
    }

    // run timeline
    // and remember its clean up handler
    ;(this: any).cleanUpTimeline = timeline.bind(this)({
      self: node,
      backdrop,
      closeButton,
      content,
      duration,
      opts: {
        onStart: () => {
          // lock scroll at start of animate in
          if (scrollLock.lock === "start" && timelineKey === "in") {
            disablePageScroll(content)
          }
          // unlock scroll at start of animate out
          if (scrollLock.unlock === "start" && timelineKey === "out") {
            enablePageScroll(content)
          }
        },
        onComplete: () => {
          // lock scroll at end of animate in
          if (scrollLock.lock === "end" && timelineKey === "in") {
            disablePageScroll(content)
          }
          // unlock scroll at end of animate out
          if (scrollLock.unlock === "end" && timelineKey === "out") {
            enablePageScroll(content)
          }
        },
      },
    })

    // remind ourselves if we forgot to set the cleanup function
    if (
      typeof this.cleanUpTimeline !== "function" &&
      this.cleanUpTimeline !== false
    ) {
      console.warn("no timeline cleanup function provided")
    }

    return true
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).open = this.open.bind(this)
    ;(this: any).close = this.close.bind(this)
    ;(this: any).toggle = this.toggle.bind(this)
    ;(this: any).setTimeline = this.setTimeline.bind(this)
  }

  componentDidMount() {
    this.setTimeline("initialState")
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { onChange } = this.props
    const { active } = this.state
    const { active: prevActive } = prevState

    if (active !== prevActive) {
      // trigger event callback
      if (typeof onChange === "function") {
        onChange.call(this, active)
      }

      // setTimeline
      this.setTimeline(active ? "in" : "out")
    }
  }

  componentWillUnmount() {
    // run clean up handler if one exists
    if (typeof this.cleanUpTimeline === "function") {
      this.cleanUpTimeline()
    }
  }

  render() {
    let {
      // element props
      backdrop,
      closeButton,
      timelines,
      duration,
      // class names
      className,
      modalClassName,
      backdropClassName,
      contentClassName,
      closeButtonClassName,
      // content
      children,
      // passthru
      ...modalProps
    } = this.props

    return (
      <div
        ref={c => (this.self = c)}
        style={{ display: "none" }}
        {...classNames("modal")
          .plus(className)
          .plus(modalClassName)}
        {...modalProps}
      >
        {backdrop && (
          <div
            ref={c => (this.backdrop = c)}
            role="presentation"
            onClick={this.close}
            {...classNames("backdrop").plus(backdropClassName)}
          />
        )}
        <div
          ref={c => (this.content = c)}
          {...classNames("content").plus(contentClassName)}
        >
          {closeButton && (
            <button
              ref={c => (this.closeButton = c)}
              aria-label="Close"
              onClick={this.close}
              {...classNames("close_button").plus(closeButtonClassName)}
              children={closeButton}
            />
          )}
          {children}
        </div>
      </div>
    )
  }
}

/**
 * Exports
 */
Modal.hide = hide
Modal.fadeIn = fadeIn
Modal.fadeOut = fadeOut
export default Modal
