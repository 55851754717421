// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Wrapper />
type Props = {
  /** Root element tag */
  tag: string,
  ...
}

/**
 * Wrapper element
 *
 * Used by higher order components that require a generic element.
 */
class Wrapper extends React.Component<Props> {
  static defaultProps = {
    tag: "div",
  }

  // react methods

  render() {
    let { tag: WrapperTag, ...wrapperProps } = this.props

    return <WrapperTag {...wrapperProps} />
  }
}

/**
 * Exports
 */
export default Wrapper
