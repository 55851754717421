// @flow

// react
import * as React from "react"
import ReactDOM from "react-dom"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// lodash
import throttle from "lodash/throttle"

// utility
import getCrossBrowserWindowValues from "../../utility/get-cross-browser-window-values"

// <ScrollPositionIntro />
type Props = {
  /** Margin between bar bottom and window bottom */
  margin: number,
  /** Custom class for intro element */
  introClassName?: string,
  /** Custom class for bar element */
  barClassName?: string,
  /** Custom class for root element */
  className?: string,
}

/**
 * A decorative bar which shows how far we've scrolled through the page.
 *
 * ## CSS Classes
 * |--------------------------|------------------------------------------------|
 * | class                    | Purpose                                        |
 * |--------------------------|------------------------------------------------|
 * | .scroll_position_intro   | Root element                                   |
 * | .bar                     | Bar element                                    |
 * |--------------------------|------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/scroll-position-intro.scss
 */
class ScrollPositionIntro extends React.Component<Props> {
  static defaultProps = {
    margin: 100,
  }

  // flags
  prevScale: number = 0

  // refs
  bar: Element | null

  // custom methods

  /** Called when user scrolls the page */
  handleScroll(e: Event) {
    this.updateTracker()
  }

  /** Scale intro according to document height & current scroll position */
  updateTracker(
    tween: {
      duration: number,
      ease: string,
      scaleY?: number,
    } = {
      duration: 0.5,
      ease: "expo.linear",
    }
  ) {
    // get barNode
    const barNode = ReactDOM.findDOMNode(this.bar)
    let doc = document.documentElement
    if (!window || !barNode || !doc) return

    // get tween scale
    const bottomMargin = this.props.margin

    const { scroll } = getCrossBrowserWindowValues()

    const containerElement = barNode.parentElement
    if (!containerElement) return

    const containerRect = containerElement.getBoundingClientRect()
    const barTop = scroll.top + containerRect.top
    const targetBarBottom = scroll.bottom - bottomMargin
    const barHeight = containerRect.height
    const targetBarHeight = targetBarBottom - barTop

    let scale = targetBarHeight / barHeight

    if (scale < 0) scale = 0
    if (scale > 1) scale = 1
    if (scale < this.prevScale) scale = this.prevScale

    tween.scaleY = scale
    this.prevScale = scale

    // animate to correct scale
    gsap.to(barNode, tween)
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).handleScroll = throttle(this.handleScroll.bind(this), 50, {
      leading: true,
      trailing: true,
    })
    ;(this: any).updateTracker = this.updateTracker.bind(this)
  }

  componentDidMount() {
    this.prevScale = 0

    // add event listeners
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleScroll)

    // set initial intro size
    this.updateTracker()
  }

  componentWillUnmount() {
    this.prevScale = 0

    // remove event listeners
    window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("resize", this.handleScroll)
  }

  render() {
    const {
      // class names
      introClassName,
      barClassName,
      className,
      // passthru
      ...introProps
    } = this.props

    return (
      <div
        {...classNames("scroll_position_intro")
          .plus(className)
          .plus(introClassName)}
        {...introProps}
      >
        <div
          ref={c => (this.bar = c)}
          {...classNames("bar").plus(barClassName)}
        ></div>
      </div>
    )
  }
}

/**
 * Exports
 */
export default ScrollPositionIntro
