// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Block />
type Props = {
  /** Root element tag */
  tag: string,
  /** Enable row background: use non-boolean for custom content */
  background: boolean | React.Node | Element,
  /** Custom class for row element */
  rowClassName?: string,
  /** Custom class for background element */
  backgroundClassName?: string,
  /** Custom class for inner element */
  innerClassName?: string,
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Content row on the css grid. Rows contain blocks.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .row             | Root element                                           |
 * | .background      | Row background                                         |
 * | .inner           | Row content wrapper                                    |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/row.scss
 * - find row @mixin at /app/core/styles/mixins/_row.scss
 * - find grid styles at /app/client/styles/placeholders/grid.scss
 */
class Row extends React.Component<Props> {
  static defaultProps = {
    tag: "section",
    background: false,
  }

  // react methods

  render() {
    const {
      // element props
      tag: RowTag,
      background,
      // class names
      rowClassName,
      backgroundClassName,
      innerClassName,
      className,
      // content
      children,
      // passthru
      ...rowProps
    } = this.props

    return (
      <RowTag
        {...classNames("row")
          .plus(className)
          .plus(rowClassName)}
        {...rowProps}
      >
        {background && (
          <div {...classNames("background").plus(backgroundClassName)}>
            {background === true ? "" : background}
          </div>
        )}
        <div {...classNames("inner").plus(innerClassName)}>{children}</div>
      </RowTag>
    )
  }
}

/**
 * Exports
 */
export default Row
