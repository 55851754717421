// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Navigation />
type Props = {
  /** Root element tag */
  tag: "ul" | "ol",
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Navigation element
 *
 * Takes an array of elements (probably links) and wraps them in nav > ul > li * semantic format.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .navigation      | Root element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/navigation.scss
 */
class Navigation extends React.Component<Props> {
  static defaultProps = {
    tag: "ul",
  }

  // react methods

  render() {
    let { tag: ListTag, className, children, ...navigationProps } = this.props

    return (
      <nav {...classNames("navigation").plus(className)} {...navigationProps}>
        <ListTag>
          {React.Children.map(children, (child, i) => (
            <li key={i}>{child}</li>
          ))}
        </ListTag>
      </nav>
    )
  }
}

/**
 * Exports
 */
export default Navigation
