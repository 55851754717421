/**
 * Turn an object into a css string
 */
function objectToCssString(object) {
  return Object.keys(object)
    .map(key => `${key}: ${object[key]};`)
    .join("")
}

export default objectToCssString
