// @flow

// react
import * as React from "react"
import ReactDOM from "react-dom"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// <Line />
type Props = {
  /** Automatically animate line */
  autoPlay: boolean,
  /** Animation duration */
  duration: number,
  /** Line direction */
  direction: "ltr" | "rtl" | "ttb" | "btt",
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Animated line element.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .line            | Root element                                           |
 * | .inner           | Animated inner element                                 |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/_line.scss
 */
class Line extends React.Component<Props> {
  static defaultProps = {
    autoPlay: true,
    duration: 1,
    direction: "ltr",
  }

  // refs
  inner: Element | null

  // component props
  timeline: Object

  // custom methods

  /** Play the line animation */
  // @todo -- implement gsapOpts (?)
  play() {
    const node = this.inner

    if (!node) return

    if (this.timeline) {
      this.timeline.play()
      return
    }

    const { direction, duration } = this.props

    // no animation if duration is zero
    if (!duration) return

    this.timeline = gsap.timeline({
      paused: false,
    })

    // @todo add line animation
  }

  /** Pause the line animation */
  pause() {
    if (this.timeline) {
      this.timeline.pause()
    }
  }

  /** remove the line animation */
  clear() {
    if (this.timeline) {
      this.timeline.clear().kill()
      delete this.timeline
    }
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).play = this.play.bind(this)
    ;(this: any).pause = this.pause.bind(this)
    ;(this: any).clear = this.clear.bind(this)
  }

  componentDidMount() {
    const { autoPlay } = this.props

    if (autoPlay) {
      this.play()
    }
  }

  componentWillUnmount() {
    this.clear()
  }

  render() {
    const {
      autoPlay,
      duration,
      direction,
      className,
      ...lineProps
    } = this.props

    return (
      <div
        {...classNames("line")
          .plus(className)
          .plus(direction)}
        {...lineProps}
      >
        <div ref={c => (this.inner = c)} {...classNames("inner")}></div>
      </div>
    )
  }
}

/**
 * Exports
 */
export default Line
