// components
import { default as SiteLogoComponent } from "../../core/components/media/site-logo"

// hocs
import withData from "../../core/hocs/data"

// queries
import useMetadata from "../queries/metadata"
import useImagesDirect from "../queries/images-direct"

// utilities
import by from "../../core/utility/by"

/**
 * Site logo image + link.
 */
const SiteLogo = withData(
  SiteLogoComponent,
  ({ metadata: site, imagesDirect: images }) => ({
    site,
    images: images.filter(by("relativePath", /site-branding/)),
  }),
  useMetadata,
  useImagesDirect
)

/**
 * Exports
 */
export default SiteLogo
