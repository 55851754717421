// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Heading />
type Props = {
  /** Root element tag */
  tag?: string,
  /** Heading importance */
  level: number,
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Generic heading
 *
 * Create headings that respect document hierarchy.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .heading           | Root element                                         |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/heading.scss
 * - find heading @mixin at /app/core/styles/mixins/_heading.scss
 */
class Heading extends React.Component<Props> {
  static defaultProps = {
    level: 2,
  }

  // react methods

  render() {
    let {
      // element props
      tag: HeadingTag,
      level,
      // class names
      className,
      // content
      children,
      // passthru
      ...headingProps
    } = this.props

    // limit levels to h2-h6
    if (level < 2) level = 2
    if (level > 6) level = 6

    // generate tag
    HeadingTag = HeadingTag || `h${level}`

    // return heading
    return (
      <HeadingTag
        data-level={level}
        {...classNames("heading").plus(className)}
        {...headingProps}
      >
        {children}
      </HeadingTag>
    )
  }
}

/**
 * Exports
 */
export default Heading
