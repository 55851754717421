// @flow

// config
import { EVENTS } from "../../../client/config"

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// lodash
import throttle from "lodash/throttle"

// <ScrollPositionTracker />
type Props = {
  /** Position of the tracker */
  position: "top" | "right" | "bottom" | "left",
  /** Custom class for tracker element */
  trackerClassName?: string,
  /** Custom class for bar element */
  barClassName?: string,
  /** Custom class for root element */
  className?: string,
}

/**
 * A decorative bar which shows how far we've scrolled through the page.
 *
 * ## CSS Classes
 * |--------------------------|------------------------------------------------|
 * | class                    | Purpose                                        |
 * |--------------------------|------------------------------------------------|
 * | .scroll_position_tracker | Root element                                   |
 * | .bar                     | Bar element                                    |
 * |--------------------------|------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/scroll-position-tracker.scss
 */
class ScrollPositionTracker extends React.Component<Props> {
  static defaultProps = {
    position: "left",
  }

  // refs
  bar: Element | null

  // custom methods

  /** Called when user scrolls the page */
  handleScroll(e: Event) {
    this.updateTracker()
  }

  /** Scale tracker according to document height & current scroll position */
  updateTracker(
    tween: {
      duration: number,
      ease: string,
      scaleX?: number,
      scaleY?: number,
    } = {
      duration: 0.5,
      ease: "expo.out",
    }
  ) {
    // get barNode
    const barNode = this.bar
    let doc = document.documentElement
    if (!window || !barNode || !doc) return

    // get tween scale
    let currPos = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    let maxPos = doc.scrollHeight - window.innerHeight
    let scale = currPos / maxPos

    switch (this.props.position) {
      case "top":
      case "bottom":
        tween.scaleX = scale
        break
      case "left":
      case "right":
        tween.scaleY = scale
        break

      default:
        break
    }

    // animate to correct scale
    gsap.to(barNode, tween)
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).handleScroll = throttle(this.handleScroll.bind(this), 50, {
      leading: true,
      trailing: true,
    })
    ;(this: any).updateTracker = this.updateTracker.bind(this)
  }

  componentDidMount() {
    // add event listeners
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleScroll)
    window.addEventListener(EVENTS.FORCE_REDETECT, this.handleScroll)

    // set initial tracker size
    this.updateTracker()
  }

  componentWillUnmount() {
    // remove event listeners
    window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("resize", this.handleScroll)
    window.removeEventListener(EVENTS.FORCE_REDETECT, this.handleScroll)
  }

  render() {
    const {
      // element props
      position,
      // class names
      trackerClassName,
      barClassName,
      className,
      // passthru
      ...trackerProps
    } = this.props

    return (
      <div
        data-position={position}
        {...classNames("scroll_position_tracker")
          .plus(className)
          .plus(trackerClassName)}
        {...trackerProps}
      >
        <div
          ref={c => (this.bar = c)}
          {...classNames("bar").plus(barClassName)}
        ></div>
      </div>
    )
  }
}

/**
 * Exports
 */
export default ScrollPositionTracker
