/**
 * Imports
 */

// config
import { EVENTS } from "../../client/config"

// @todo -- this might be better as not-a-hook
// but just a script that runs in html.js

// react
import { useLayoutEffect } from "react"

/**
 * useScrollBodyClass()
 */
function useScrollBodyClass(selector) {
  useLayoutEffect(() => {
    // get element
    const body = document.body
    const html = document.documentElement

    if (!body) return

    let prevScrollTop = 0

    let setClass = function() {
      // scroll
      const scrollTop = window.pageYOffset

      // heights
      var winHeight = window.innerHeight
      var docHeight = html.scrollHeight

      // set body class
      let bodyClass
      if (scrollTop <= 0) {
        bodyClass = "scroll-top"
      } else if (scrollTop >= docHeight - winHeight) {
        bodyClass = "scroll-bottom"
      } else if (scrollTop < prevScrollTop) {
        bodyClass = "scroll-up"
      } else {
        bodyClass = "scroll-down"
      }

      body.classList.remove(
        "scroll-top",
        "scroll-bottom",
        "scroll-up",
        "scroll-down",
        "above-the-fold",
        "below-the-fold"
      )
      body.classList.add(bodyClass)

      if (scrollTop < winHeight) {
        body.classList.add("above-the-fold")
      } else {
        body.classList.add("below-the-fold")
      }

      prevScrollTop = scrollTop
    }

    setClass = setClass.bind(body)

    requestAnimationFrame(setClass)
    window.addEventListener("resize", setClass)
    window.addEventListener("scroll", setClass)
    window.addEventListener(EVENTS.FORCE_REDETECT, setClass)

    return () => {
      window.removeEventListener("resize", setClass)
      window.removeEventListener("scroll", setClass)
      window.removeEventListener(EVENTS.FORCE_REDETECT, setClass)
    }
  })
}

/**
 * Exports
 */
export default useScrollBodyClass
