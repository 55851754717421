/**
 * Imports
 */

// @todo -- this might be better as not-a-hook
// but just a script that runs in html.js

// react
import { useLayoutEffect } from "react"

// utility
import createNode from "../utility/create-node"
import destroyNode from "../utility/destroy-node"
import setCssVariable from "../utility/set-css-variable"

/**
 * Exports
 */
export default function useDetectScrollbarWidth() {
  useLayoutEffect(() => {
    let node = createNode(
      {
        width: "100px",
        height: "100px",
        overflow: "scroll",
        position: "absolute",
        top: "-9999px",
      },
      document.body
    )

    const scrollbarWidth = node.offsetWidth - node.clientWidth

    setCssVariable("fvw", `calc(100vw - ${scrollbarWidth}px)`)

    destroyNode(node)
  }, [])
}
