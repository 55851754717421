// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <SharePage />
type Props = {
  /** Create an external link */
  targets: Array<"facebook" | "linkedin">,
  /** Root element class name */
  className?: string,
  ...
}

// get current page url
const getEncodedCanonicalUrl = () => {
  const canconical = document.querySelector(
    "link[rel='canonical'][href^='http']"
  )

  let url = canconical && canconical.getAttribute("href")

  if (!url) {
    url = self.location.href || ""
  }

  if (typeof url === "string") {
    return encodeURIComponent(url)
  }

  return ""
}

const getEncodedTitle = () => {
  const title = document.title.split("|").shift()

  if (typeof title === "string") {
    return encodeURIComponent(title)
  }

  return ""
}

const getEncodedDescription = () => {
  const description = document.querySelector("link[name='description']")
  const content = description && description.getAttribute("content")

  if (typeof content === "string") {
    return encodeURIComponent(content)
  }

  return ""
}

/**
 * Generates links to share the current page.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .share_page      | Root element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/share-page.scss
 */
class SharePage extends React.Component<Props> {
  props: Props

  static Facebook: Class<FacebookLink>
  static LinkedIn: Class<LinkedInLink>

  static defaultProps = {
    targets: ["facebook", "linkedin"],
  }

  // react methods

  render() {
    let { targets, className, ...passthruProps } = this.props

    const targetToLink = target => {
      switch (target) {
        case "facebook":
          return <SharePage.Facebook />

        case "linkedin":
          return <SharePage.LinkedIn />

        default:
          return null
      }
    }

    let shareLinks

    // single link
    if (targets.length === 1) {
      shareLinks = targetToLink(targets[0])
    }
    // multiple links wrapped with <ul>
    else {
      shareLinks = (
        <ul>
          {targets.map(targetToLink).map((link, i) => (
            <li key={i}>{link}</li>
          ))}
        </ul>
      )
    }

    return (
      <div {...classNames("share_page").plus(className)}>
        <h3 className="heading">Share on</h3>
        {shareLinks}
      </div>
    )
  }
}

/**
 * Share on facebook
 */
class FacebookLink extends React.Component<{}> {
  static urlPrefix = "https://www.facebook.com/sharer/sharer.php?u="

  render() {
    const url = FacebookLink.urlPrefix + getEncodedCanonicalUrl()

    return (
      <a
        href={url}
        {...classNames("facebook anchor")}
        // ensure no data leaked to external pages
        rel="noopener noreferrer"
        target="_blank"
      >
        Facebook
      </a>
    )
  }
}

/**
 * Share on linkedin
 */
class LinkedInLink extends React.Component<{}> {
  static urlPrefix = "https://www.linkedin.com/shareArticle?mini=true&url="

  render() {
    const url = LinkedInLink.urlPrefix + getEncodedCanonicalUrl()

    return (
      <a
        href={url}
        {...classNames("linkedin anchor")}
        // ensure no data leaked to external pages
        rel="noopener noreferrer"
        target="_blank"
        onClick={e => {
          // include metadata at time of click
          let enhancedUrl =
            url +
            "&title=" +
            getEncodedTitle() +
            "&summary=" +
            getEncodedDescription()

          // LINKEDIN HAS CLOSED THIS API FOR NOW
          enhancedUrl = "https://www.linkedin.com/feed/"

          // prevent normal behaviour
          e.preventDefault()
          e.stopPropagation()

          // open safely in new window
          const a = document.createElement("a")
          a.href = enhancedUrl
          a.target = "_blank"
          a.rel = "noopener noreferrer"
          a.click()

          return false
        }}
      >
        LinkedIn
      </a>
    )
  }
}

/**
 * Exports
 */
SharePage.Facebook = FacebookLink
SharePage.LinkedIn = LinkedInLink

export default SharePage
