// @flow

// react
import * as React from "react"
import ReactDOM from "react-dom"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// lodash
import throttle from "lodash/throttle"

// utility
import isSSR from "../../utility/is-ssr"

// <Marquee />
type Props = {
  /** Automatically animate marquee */
  autoPlay: boolean,
  /** Marquee speed of movement */
  speed: number,
  /** Marquee direction */
  direction: "ltr" | "rtl",
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Animated marquee element.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .marquee         | Root element                                           |
 * | .inner           | Animated inner element                                 |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/marquee.scss
 */
class Marquee extends React.Component<Props> {
  static defaultProps = {
    autoPlay: true,
    speed: 75,
    direction: "ltr",
  }

  // refs
  inner: Element | null

  // component props
  timeline: Object

  // custom methods

  /** Play the marquee animation */
  // @todo -- implement gsapOpts (?)
  play() {
    const node = this.inner

    if (!node) return

    if (this.timeline) {
      this.timeline.play()
      return
    }

    const { direction, speed } = this.props
    const distance = node.getBoundingClientRect().width

    const duration = (distance * 0.5) / speed

    // no animation if duration is zero
    if (!duration) return

    this.timeline = gsap
      .timeline({
        paused: false,
      })
      .fromTo(
        node,
        { x: { ltr: "0%", rtl: "-50%" }[direction] },
        {
          duration,
          x: { ltr: "-50%", rtl: "0%" }[direction],
          ease: "linear.none",
          repeat: -1,
        }
      )
  }

  /** Pause the marquee animation */
  pause() {
    if (this.timeline) {
      this.timeline.pause()
    }
  }

  /** update the marquee animation (in case of new content/window size) */
  update() {
    let paused = !this.props.autoPlay

    if (this.timeline) {
      ;({ paused } = this.timeline.vars)
    }

    // remove current animation
    this.clear()

    // generate new animation
    this.play()

    // pause if old animation was paused
    if (paused) {
      this.pause()
    }
  }

  /** remove the marquee animation */
  clear() {
    if (this.timeline) {
      this.timeline.clear().kill()
      delete this.timeline
    }
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).play = this.play.bind(this)
    ;(this: any).pause = this.pause.bind(this)
    ;(this: any).update = throttle(this.update, 100, {
      leading: true,
      trailing: true,
    }).bind(this)
    ;(this: any).clear = this.clear.bind(this)
  }

  componentDidMount() {
    const { autoPlay } = this.props

    if (autoPlay) {
      this.play()
    }
  }

  componentWillUnmount() {
    this.clear()
  }

  render() {
    const { autoPlay, speed, className, children, ...marqueeProps } = this.props

    // animation should change each render
    // AFTER the component has been rendered
    // (if function exists -- we might be ssring)
    if (!isSSR()) {
      requestAnimationFrame(this.update)
    }

    return (
      <div {...classNames("marquee").plus(className)} {...marqueeProps}>
        <div ref={c => (this.inner = c)} {...classNames("inner")}>
          {children}
          {children}
          {children}
          {children}
          {children}
          {children}
          {children}
        </div>
      </div>
    )
  }
}

/**
 * Exports
 */
export default Marquee
