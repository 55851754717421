// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// components
import Card from "../semantic/card"

// utility
import queryData from "../../utility/query-data"

// <List />
type Props = {
  /** Component data, perhaps provided by withData() */
  data: {
    /** Array of list items */
    list: Array<{
      /** Thumbnail element */
      thumbnail?: React.Node | Element,
      /** Name of thumbnail in images array, ignored if thumbnail is set */
      image?: string,
      /** List item content */
      content?: React.Node | string,
      ...
    }>,
    /** Array of images for data.list to reference */
    images: Array<{
      /** Image name (referenced by data.list) */
      name: string,
      /** Image element */
      element: React.Node,
      ...
    }>,
  },
  /** Element tag */
  tag: "ul" | "ol",
  /** List title */
  title?: string,
  /** Custom class for root element */
  className?: string,
  /** Custom class for list element */
  listClassName?: string,
  /** Custom class for list item element */
  itemClassName?: string,
  /** content (if set, data.list is ignored) */
  children?: React.Node | null,
  ...
}

/**
 * List of <Card> components in a <ul> or <ol> element.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .list            | Root element                                           |
 * | .item            | Item element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/list.scss
 */
class List extends React.Component<Props> {
  static defaultProps = {
    tag: "ul",
    data: {
      list: [],
      images: [],
    },
  }

  render() {
    let {
      // element data
      data,
      // element props
      tag: ListTag,
      title,
      // class names
      listClassName,
      itemClassName,
      className,
      // content
      children,
      // passthru
      ...listProps
    } = this.props

    return (
      <ListTag
        {...classNames("list")
          .plus(className)
          .plus(listClassName)}
        {...listProps}
      >
        {children
          ? React.Children.map(children, (child, i) =>
              React.cloneElement(
                child,
                Object.assign(
                  {
                    key: i,
                  },
                  classNames("item").plus(itemClassName)
                )
              )
            )
          : data.list.map((itemProps, i) => {
              let {
                thumbnail,
                image,
                content: children,
                ...cardProps
              } = itemProps

              // get thumbnail
              if (!thumbnail && data.images) {
                thumbnail = queryData(data.images)
                  .get("element")
                  .where("name")
                  .is(image)
              }

              return (
                <Card
                  tag="li"
                  key={i}
                  thumbnail={thumbnail}
                  {...classNames("item").plus(itemClassName)}
                  {...cardProps}
                >
                  {children}
                </Card>
              )
            })}
      </ListTag>
    )
  }
}

/**
 * Exports
 */
export default List
