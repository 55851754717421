import objectToCssString from "./object-to-css-string"

function createNode(styles, parentNode) {
  let node = document.createElement("div")
  node.setAttribute(`style`, objectToCssString(styles))
  parentNode.appendChild(node)

  return node
}

export default createNode
