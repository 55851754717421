// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/props"

// components
import Row from "../../core/components/grid/row"
import withGSAPBulkAnimation from "../../core/animations/gsap-bulk"

/**
 * Animate map
 */

const Loader = withProps(
  withGSAPBulkAnimation(Row, {
    onStart: () => {
      document.querySelector(".site_wrapper").style.opacity = 1
    },
    delay: 3.5,
    autoplay: true,
    loop: false,
    duration: 2,
    y: 0,
    display: "block",
    opacity: 1,
    ease: "expo.inOut",
  }),
  { id: "loader" }
)

/**
 * Exports
 */
export default Loader
