// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// components
import Anchor from "../semantic/anchor"
import Heading from "../semantic/heading"

// <Card />
type Props = {
  /** Root element tag */
  tag: string,
  /** Custom thumbnail element */
  thumbnail?: React.Element<"img" | "svg"> | null,
  /** Component title */
  title?: string,
  /** Component title link */
  titleLink?: {
    to?: string,
    url?: string,
    elementId?: string,
  },
  /** Create an external link */
  url?: string,
  /** Create an internal link */
  to?: string,
  /** Create a smooth scroll link */
  elementId?: string,
  /** Create an email link */
  email?: string,
  /** Create a telephone/fax link */
  telephone?: string,
  /** Create a link to an address */
  address?: string,
  /** Optional placeId when linking to an address */
  placeId?: string,
  /** Custom class for card element */
  cardClassName?: string,
  /** Custom class for thumbnail element */
  thumbnailClassName?: string,
  /** Custom class for title element */
  titleClassName?: string,
  /** Custom class for content element */
  contentClassName?: string,
  /** Root element class name */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Card with thumbnail, title, and content.
 *
 * Can be linked with the same properties as <Anchor />.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .card            | Root element                                           |
 * | .thumbnail       | Thumbnail element                                      |
 * | .title           | Title element                                          |
 * | .content         | Content element                                        |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/card.scss
 * - create a list of cards with /app/core/components/content/list.js
 * - create a carousel of cards with /app/core/components/content/carousel.js
 */
class Card extends React.Component<Props> {
  static defaultProps = {
    tag: "div",
  }

  // react methods

  render() {
    let {
      // wrapper tag
      tag: CardTag,
      // image element
      thumbnail,
      // card title
      title,
      titleLink,
      // optional anchor props
      url,
      to,
      elementId,
      email,
      telephone,
      address,
      placeId,
      // class names
      cardClassName,
      thumbnailClassName,
      titleClassName,
      contentClassName,
      className,
      // content
      children,
      // passthru props
      ...cardProps
    } = this.props

    const anchorProps = {
      url,
      to,
      elementId,
      email,
      telephone,
      address,
      placeId,
    }

    // wrap content if thumbnail/title elements exist
    if (thumbnail || title) {
      children = (
        <div {...classNames("content").plus(contentClassName)}>{children}</div>
      )
    }

    // add props to thumbnail
    thumbnail = thumbnail
      ? React.cloneElement(thumbnail, {
          alt: title,
          role: null,
          className: classNames("thumbnail").plus(thumbnailClassName).className,
        })
      : null

    title = title && (
      <Heading level={5} {...classNames("title").plus(titleClassName)}>
        {title}
      </Heading>
    )

    if (title && typeof titleLink === "object") {
      title = <Anchor {...titleLink}>{title}</Anchor>
    }

    return (
      <CardTag
        {...classNames("card")
          .plus(className)
          .plus(cardClassName)}
        {...cardProps}
      >
        <Anchor {...anchorProps}>
          {thumbnail}
          {title}
          {children}
        </Anchor>
      </CardTag>
    )
  }
}

/**
 * Exports
 */
export default Card
