/**
 * Get element from data where data is in the form
 *
 * [
 *   {
 *     name: "element_name",
 *     element: <div>An Element</div>
 *   },
 *   ...
 * ]
 */
import by from "./by"

function dataQuery(arrayOfObjects) {
  return {
    get: fieldToReturn => ({
      where: fieldToMatch => ({
        is: valueToMatch => {
          let result =
            arrayOfObjects.filter(by(fieldToMatch, valueToMatch))[0] || {}

          return fieldToReturn === "*" ? result : result[fieldToReturn]
        },
      }),
    }),
    getAll: () => ({
      where: fieldToMatch => ({
        is: valueToMatch => {
          return arrayOfObjects.filter(by(fieldToMatch, valueToMatch))
        },
      }),
    }),
  }
}

export default dataQuery
