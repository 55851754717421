// @flow

// react
import * as React from "react"

// lodash
import throttle from "lodash/throttle"

// utility
import isSSR from "../../utility/is-ssr"

// <Listener />
type Props = {
  /** Init function */
  init: (on: Function) => void,
  /** If true, use passive event listeners */
  passive: boolean,
  /** Throttle settings */
  throttle: {
    /** throttle delay */
    delay: number,
    /** trigger event on first occurance */
    leading: boolean,
    /** trigger event on last occurance */
    trailing: boolean,
  },
  /** Elements to render */
  elements: Array<React.Node> | null,
}

/**
 * Setup logic based on window events
 */
class Listener extends React.Component<Props> {
  static defaultProps = {
    init: function() {},
    passive: true,
    throttle: {
      delay: 100,
      leading: true,
      trailing: true,
    },
    elements: null,
  }

  listeners: Array<{ eventName: string, func: Function }> = []

  // custom methods

  // react methods

  constructor(props: Props) {
    super(props)

    if (isSSR()) return

    const { init } = props
    const { delay, leading, trailing } = props.throttle

    console.log("run listener init", this.listeners)
    init.call(
      this,
      ((eventName, func) => {
        // validate name
        if (typeof eventName !== "string") {
          console.warn("listener must have valid event name", eventName, func)
          return
        }

        // validate handler
        if (typeof func !== "function") {
          console.warn(
            "listener must have valid handler function",
            eventName,
            func
          )
          return
        }

        // add to listeners
        const disableThrottleForEvents = [
          "click",
          "touchstart",
          "touchend",
          "mousedown",
          "mouseup",
        ]

        this.listeners.push({
          eventName,
          func:
            delay && !disableThrottleForEvents.includes(eventName)
              ? throttle(func.bind(this), delay, { leading, trailing })
              : func.bind(this),
        })
      }).bind(this)
    )
  }

  componentDidMount() {
    // add event listeners
    console.log("add listeners", this.listeners)
    const { passive } = this.props

    this.listeners.forEach(({ eventName, func }) =>
      window.addEventListener(eventName, func, { passive })
    )
  }

  componentWillUnmount() {
    // remove event listeners
    console.log("remove listeners", this.listeners)

    this.listeners.forEach(({ eventName, func }) =>
      window.removeEventListener(eventName, func)
    )
  }

  render() {
    return <>{this.props.elements}</>
  }
}

/**
 * Exports
 */
export default Listener
