/** Parallax effet strength */
const INTERVAL = 2
/** Parallax effect duration (s) */
const DURATION = 1

// config
import { EVENTS } from "../config"

// react
import * as React from "react"

// gsap
import gsap from "gsap"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"

// utility
import callWhenIdle from "../../core/utility/call-when-idle"
import isMobile from "../../core/utility/is-mobile"

// assets
import MapSvg from "../assets/images/pages/home/map.inline.svg"

/**
 * Animate map
 */

const AnimateMap = withProps(Listener, null, {
  elements: [<MapSvg key="map" />],
  init: function(on) {
    on(EVENTS.INITIAL_CLIENT_RENDER, () => {
      const svg = document.querySelector("#map svg")
      const paths = Array.from(svg.querySelectorAll("path:not(#circle)"))

      const animate = () => {
        paths.forEach(path => {
          if (Math.random() > 0.01) return

          path.style["animation-name"] = "blink"
          path.style["animation-delay"] = `${Math.random() * 15}s`
        })
      }

      setTimeout(() => callWhenIdle(animate), 2800)
    })
  },
})

/**
 * Exports
 */
export default AnimateMap
