// @flow
// config
import { EVENTS } from "../../../client/config"

// react
import * as React from "react"
import ReactDOM from "react-dom"
import classNames from "react-css-module-classnames"

// flickity
import Flickity from "react-flickity-component"

// gsap
import gsap from "gsap"

// components
import Card from "../semantic/card"

// utility
import queryData from "../../utility/query-data"
import triggerEvent from "../../utility/trigger-event"

// <Carousel />
type Props = {
  /** Component data, perhaps provided by withData() */
  data: {
    /** Array of list items */
    list: Array<{
      /** Custom thumbnail element */
      thumbnail?: React.Node | Element,
      /** Name of thumbnail in images array, ignored if thumbnail is set */
      image?: string,
      /** List item content */
      content?: React.Node | string,
      ...
    }>,
    /** Array of images for data.list to reference */
    images: Array<{
      /** Image name (referenced by data.list) */
      name: string,
      /** Image element */
      element: React.Node | Element,
      ...
    }>,
  },
  /** Root element tag */
  tag: string,
  /**
   * Creates and enables custom scrollbar.
   */
  scrollBar: boolean,
  /**
   * flickity props
   * @see https://github.com/theolampert/react-flickity-component
   */
  flickityProps: {
    /** Flickity element tag */
    elementType?: string,
    /** Disable call reloadCells images are loaded */
    disableImagesLoaded?: boolean,
    /** Run reloadCells and resize on componentDidUpdate */
    reloadOnUpdate?: boolean,
    /**
     * Carousel contents are static and not updated at runtime. Useful for
     * smoother server-side rendering however the carousel contents cannot be
     * updated dynamically.
     */
    static?: boolean,
    /**
     * Flickity initialization opions
     */
    options?: {
      /** Enables dragging and flicking.  */
      draggable?: boolean,
      /**
       * Enables content to be freely scrolled and flicked without aligning
       * cells to an end position.
       */
      freeScroll?: boolean,
      /**
       * Higher friction makes the slider feel stickier. Lower friction makes
       * the slider feel looser.
       */
      freeScrollFriction?: number,
      /**
       * At the end of cells, wrap-around to the other end for infinite
       * scrolling.
       */
      wrapAround?: boolean,
      /**  Groups cells together in slides. */
      groupCells?: boolean | number | string,
      /** Automatically advances to the next cell. */
      autoPlay?: boolean | number,
      /** Pause autoPlay on hover */
      pauseAutoPlayOnHover?: boolean,
      /**
       * Enables fullscreen view of carousel. Adds button to view and exit
       * fullscreen.
       */
      fullscreen?: boolean,
      /** Fades between transitioning slides instead of moving. */
      fade?: boolean,
      /** Changes height of carousel to fit height of selected slide. */
      adaptiveHeight?: boolean,
      /**
       * watchCSS option watches the content of :after of the carousel element.
       * Flickity is enabled if :after content is 'flickity'.
       */
      watchCSS?: boolean,
      /**
       * Use one Flickity carousel as navigation for another.
       * - Clicking the nav carousel will select the content carousel.
       * - Selecting a cell in the content carousel will sync to the nav
       *   carousel.
       */
      asNavFor?: React.Node | string,
      /**
       * The number of pixels a mouse or touch has to move before dragging
       * begins. Increase dragThreshold to allow for more wiggle room for
       * vertical page scrolling on touch devices.
       */
      dragThreshold?: number,
      /**
       * Attracts the position of the slider to the selected cell. Higher
       * attraction makes the slider move faster. Lower makes it move slower.
       */
      selectedAttraction?: number,
      /**
       * Slows the movement of slider. Higher friction makes the slider feel
       * stickier and less bouncy. Lower friction makes the slider feel looser
       * and more wobbly.
       */
      friction?: number,
      /**
       * Unloaded images have no size, which can throw off cell positions. To
       * fix this, the imagesLoaded option re-positions cells once their images
       * have loaded.
       */
      imagesLoaded?: boolean,
      /** Loads cell images when a cell is selected. */
      lazyLoad?: boolean,
      /**
       * Specify selector for cell elements. cellSelector is useful if you have
       * other elements in your carousel elements that are not cells.
       */
      cellSelector?: string,
      /**
       * Zero-based index of the initial selected cell.
       */
      initialIndex?: number,
      /**
       * Enable keyboard navigation.
       */
      accessibility?: boolean,
      /**
       * Sets the height of the carousel to the height of the tallest cell.
       */
      setGallerySize?: boolean,
      /**
       * Adjusts sizes and positions when window is resized.
       */
      resize?: boolean,
      /**
       * Align cells within the carousel element.
       */
      cellAlign?: "left" | "center" | "right",
      /**
       * Contains cells to carousel element to prevent excess scroll at
       * beginning or end. Has no effect if `wrapAround: true`.
       */
      contain?: true,
      /**
       * Sets positioning in percent values, rather than pixel values.
       */
      percentPosition?: boolean,
      /**
       * Enables right-to-left layout.
       */
      rightToLeft?: boolean,
      /**
       * Creates and enables previous & next buttons.
       */
      prevNextButtons?: boolean,
      /**
       * Creates and enables page dots.
       */
      pageDots?: boolean,
    },
  },
  /** Custom class for carousel element */
  carouselClassName?: string,
  /** Custom class for cell item element */
  cellClassName?: string,
  /** Custom class for root element */
  className?: string,
  /** content (if set, data.list is ignored) */
  children?: React.Node | null,
  ...
}

/**
 * Draggable carousel with scrollbar powered by Flickity.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .carousel        | Root element                                           |
 * | .cell            | Cell element                                           |
 * | .scrollbar       | Scrollbar element                                      |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/carousel.scss
 * - [Flickity API](https://flickity.metafizzy.co/)
 */
class Carousel extends React.Component<Props> {
  static defaultProps = {
    data: {
      list: [],
      images: [],
    },
    tag: "div",
    scrollBar: true,
    flickityProps: {
      elementType: "div",
      disableImagesLoaded: false,
      reloadOnUpdate: false,
      static: true,
      options: {
        draggable: true,
        freeScroll: false,
        freeScrollFriction: 0.075,
        wrapAround: false,
        groupCells: false,
        autoPlay: false,
        pauseAutoPlayOnHover: true,
        fullscreen: false,
        fade: false,
        adaptiveHeight: false,
        watchCSS: false,
        asNavFor: "",
        dragThreshold: 3,
        selectedAttraction: 0.025,
        friction: 0.28,
        imagesLoaded: true,
        lazyLoad: false,
        cellSelector: ".cell",
        initialIndex: 0,
        accessibility: true,
        setGallerySize: true,
        resize: true,
        cellAlign: "left",
        contain: true,
        percentPosition: false,
        rightToLeft: false,
        prevNextButtons: false,
        pageDots: false,
      },
    },
  }

  // refs
  scrollBar: Element | null
  flkty: Flickity

  // custom methods
  resize() {
    requestAnimationFrame(() => {
      this.flkty.resize()
      triggerEvent(EVENTS.FORCE_REDETECT)
    })
  }

  /** Handle scroll events  */
  handleScroll(e?: Event, progress?: number) {
    this.renderScrollBar(progress)
  }

  /** Render the scroll bar if enabled  */
  renderScrollBar(progress?: number = 0) {
    // get element node
    const node = this.scrollBar

    // ensure component is enabled && mounted
    if (!this.flkty || !this.props.scrollBar || !node) return

    // calculate scale and x position of scrollbar
    let { slideableWidth, slidesWidth, size } = this.flkty

    let currPos = progress
    let maxPos = slidesWidth
    let percent = currPos / maxPos

    let trackWidth = size.width
    let barWidth = slidesWidth / slideableWidth

    // animate scrollbar parameters
    gsap.to(node, {
      x: percent * (trackWidth - barWidth * trackWidth),
      scaleX: barWidth,
      duration: 0.5,
      ease: "linear.none",
    })
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).resize = this.resize.bind(this)
    ;(this: any).handleScroll = this.handleScroll.bind(this)
    ;(this: any).renderScrollBar = this.renderScrollBar.bind(this)
  }

  componentDidMount = () => {
    this.handleScroll()
    this.flkty.on("scroll", this.handleScroll)

    // fix incorrect sizing due to gatsby cached page loading
    this.resize()

    // fix incorrect sizing after routeUpdate calls
    window.addEventListener(EVENTS.ROUTE_UPDATE, this.resize)
  }

  componentWillUnmount = () => {
    this.flkty.off("scroll", this.handleScroll)
    window.removeEventListener(EVENTS.ROUTE_UPDATE, this.resize)
  }

  render() {
    let {
      // element data
      data,
      // element props
      tag: CarouselTag,
      scrollBar,
      // flickity props
      flickityProps,
      // classes
      carouselClassName,
      cellClassName,
      className,
      // content
      children,
      // passthru
      ...carouselProps
    } = this.props

    // construct flickity props with defaults
    flickityProps = Object.assign(
      {},
      Carousel.defaultProps.flickityProps,
      flickityProps
    )

    return (
      <CarouselTag
        {...classNames("carousel")
          .plus(className)
          .plus(carouselClassName)}
        style={{ width: "100%" }}
        {...carouselProps}
      >
        <Flickity flickityRef={c => (this.flkty = c)} {...flickityProps}>
          {children
            ? React.Children.map(children, (child, i) =>
                React.cloneElement(
                  child,
                  Object.assign(
                    {
                      key: i,
                      style: { display: "block" },
                    },
                    classNames("cell").plus(cellClassName)
                  )
                )
              )
            : data.list.map((itemProps, i) => {
                let {
                  image,
                  content: children,
                  thumbnail,
                  ...cardProps
                } = itemProps

                // get thumbnail
                if (!thumbnail && data.images) {
                  thumbnail = queryData(data.images)
                    .get("element")
                    .where("name")
                    .is(image)
                }

                return (
                  <Card
                    key={i}
                    thumbnail={thumbnail}
                    {...classNames("cell").plus(cellClassName)}
                    {...cardProps}
                  >
                    {children}
                  </Card>
                )
              })}
        </Flickity>
        {scrollBar && (
          <div {...classNames("scrollbar")}>
            <div ref={c => (this.scrollBar = c)}></div>
          </div>
        )}
      </CarouselTag>
    )
  }
}

/**
 * Exports
 */
export default Carousel
