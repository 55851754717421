// @flow
// config
import { EVENTS } from "../../../client/config"

// react
import * as React from "react"
import ReactDOM from "react-dom"
import classNames from "react-css-module-classnames"

// animation
import gsap from "gsap"

// utility
import triggerEvent from "../../../core/utility/trigger-event"
import callWhenIdle from "../../../core/utility/call-when-idle"

// <Accordian />
type Props = {
  /** Accordian label */
  label: string | React.Node | Element,
  /** Animation duration (seconds) */
  duration: number,
  /**
   * ## Animation timeline functions
   *
   * For convenience, each function recieves a single argument, an object with
   * the accordian elements and props.
   */
  timelines: {
    /** Handles animation initial state; returns a cleanup function */
    initialState: (args: {
      self?: Element | null,
      label?: Element | null,
      content?: Element | null,
    }) => Function,
    /** Handles animation in; returns a cleanup function */
    in: (args: {
      self?: Element | null,
      label?: Element | null,
      content?: Element | null,
      duration: number,
    }) => Function,
    /** Handles animation out; returns a cleanup function */
    out: (args: {
      self?: Element | null,
      label?: Element | null,
      content?: Element | null,
      duration: number,
    }) => Function,
  },
  /** Root element class name */
  className?: string,
  /** Accordian custom class name */
  accordianClassName?: string,
  /** Label custom class name */
  labelClassName?: string,
  /** Content custom class name */
  contentClassName?: string,
  /** onChange handler */
  onChange?: Function,
  /** Content */
  children: React.Node,
  ...
}

type State = {
  /** Active: true if button is active */
  active: boolean,
}

/**
 * Timeline Functions
 */

/** Set opacity of backdrop and content to zero */
const hide = ({
  self,
  label,
  content,
}: {
  self?: Element | null,
  label?: Element | null,
  content?: Element | null,
}) => {
  const timeline = gsap.timeline().to(
    [content].filter(el => el),
    { duration: 0, height: 0 }
  )

  return () => timeline.clear().kill()
}

/** Animate height of content to 100% */
const slideIn = ({
  self,
  label,
  content,
  duration,
}: {
  self?: Element | null,
  label?: Element | null,
  content?: Element | null,
  duration: number,
}) => {
  const timeline = gsap.timeline().to(
    [content].filter(el => el),
    {
      duration,
      height: "auto",
      ease: "expo.in",
    }
  )

  return () => timeline.clear().kill()
}

/** Animate height of content to 0 */
const slideOut = ({
  self,
  backdrop,
  content,
  duration,
}: {
  self?: Element | null,
  backdrop?: Element | null,
  content?: Element | null,
  duration: number,
}) => {
  const timeline = gsap.timeline().to(
    [backdrop, content].filter(el => el),
    { duration, height: 0, ease: "expo.out" }
  )

  return () => timeline.clear().kill()
}

/**
 * Animated accordian element
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .accordian       | Root element                                           |
 * | .label           | Label element                                          |
 * | .content         | Content element                                        |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/accordian.scss
 */
class Accordian extends React.Component<Props, State> {
  static defaultProps = {
    duration: 0.5,
    timelines: {
      initialState: hide,
      in: slideIn,
      out: slideOut,
    },
  }

  state = {
    active: false,
  }

  // refs
  self: Element | null
  label: Element | null
  content: Element | null

  // custom methods

  /** Show accordian content */
  open() {
    this.setState({ active: true })
  }

  /** Hide accordian content */
  close() {
    this.setState({ active: false })
  }

  /** Toggle accordian content */
  toggle() {
    this.setState({ active: !this.state.active })
  }

  /** Set accordian animation timeline  */
  setTimeline(timelineKey: "initialState" | "in" | "out") {
    let { timelines, duration } = this.props

    // get node
    const node = this.self
    if (!node) return

    // get other nodes
    const label = this.label
    const content = this.content

    // get timeline function
    timelines = Object.assign({}, Accordian.defaultProps.timelines, timelines)
    const timeline = timelines[timelineKey]
    if (typeof timeline !== "function") return

    // run clean up handler if one exists
    if (typeof this.cleanUpTimeline === "function") {
      this.cleanUpTimeline()
    }

    // run timeline
    // and remember its clean up handler
    ;(this: any).cleanUpTimeline = timeline.bind(this)({
      self: node,
      label,
      content,
      duration,
    })

    // remind ourselves if we forgot to set the cleanup function
    if (typeof this.cleanUpTimeline !== "function") {
      console.warn("no timeline cleanup function provided")
    }

    // trigger redetection
    callWhenIdle(() => triggerEvent(EVENTS.FORCE_REDETECT), duration * 1000)

    return true
  }

  cleanUpTimeline() {
    // replaced by setTimeline()
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).open = this.open.bind(this)
    ;(this: any).close = this.close.bind(this)
    ;(this: any).toggle = this.toggle.bind(this)
    ;(this: any).setTimeline = this.setTimeline.bind(this)
  }

  componentDidMount() {
    this.setTimeline("initialState")
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { onChange } = this.props
    const { active } = this.state
    const { active: prevActive } = prevState

    if (active !== prevActive) {
      // trigger event callback
      if (typeof onChange === "function") {
        onChange.call(this, active)
      }

      // setTimeline
      this.setTimeline(active ? "in" : "out")
    }
  }

  componentWillUnmount() {
    // run clean up handler if one exists
    if (typeof this.cleanUpTimeline === "function") {
      this.cleanUpTimeline()
    }
  }

  render() {
    let {
      // element props
      label,
      timelines,
      duration,
      // class names
      className,
      accordianClassName,
      labelClassName,
      contentClassName,
      // content
      children,
      // passthru
      ...accordianProps
    } = this.props

    const { active } = this.state

    return (
      <div
        ref={c => (this.self = c)}
        {...classNames("accordian")
          .plus(className)
          .plus(accordianClassName)
          .plus(active ? "active" : "")}
        {...accordianProps}
      >
        {label && (
          <button
            ref={c => (this.label = c)}
            onClick={this.toggle}
            {...classNames("label").plus(labelClassName)}
          >
            {label}
          </button>
        )}
        <div
          ref={c => (this.content = c)}
          style={{ overflow: "hidden" }}
          {...classNames("content").plus(contentClassName)}
        >
          {children}
        </div>
      </div>
    )
  }
}

/**
 * Exports
 */
export default Accordian
