// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Block />
type Props = {
  /** Root element tag */
  tag: string,
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

/**
 * Content block on the css grid. Blocks are contained in Rows.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .block           | Root element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/block.scss
 * - find block @mixin at /app/core/styles/mixins/_block.scss
 * - find grid styles at /app/client/styles/placeholders/grid.scss
 */
class Block extends React.Component<Props> {
  static defaultProps = {
    tag: "div",
  }

  // react methods

  render() {
    const { tag: BlockTag, className, children, ...blockProps } = this.props

    return (
      <BlockTag {...classNames("block").plus(className)} {...blockProps}>
        {children}
      </BlockTag>
    )
  }
}

/**
 * Exports
 */
export default Block
