// components
import { default as SEOComponent } from "../../core/components/metadata/seo"

// hocs
import withData from "../../core/hocs/data"

// queries
import useMetadata from "../queries/metadata"

/**
 * Generate <head> metadata with data from gatsby-config.js.
 */
const SEO = withData(
  SEOComponent,
  ({ page, metadata }) => ({
    page,
    site: metadata,
    metadata: [],
  }),
  useMetadata
)

/**
 * Exports
 */
export default SEO
