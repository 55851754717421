/**
 *  Imports
 */
import React from "react"

/**
 * withPropsShorthand()
 *
 * Add props to a Component.
 *
 * // <Enhanced /> will have class "enhanced"
 * // unless overwritten like <Enhanced className="overwritten" />
 * const Enhanced = withProps(Component, { className: "enhanced"})
 */
const withPropsShorthand = function(WrappedComponent, ...defaultProps) {
  return class extends React.Component {
    render() {
      const allProps = Object.assign({}, ...defaultProps, this.props)
      return <WrappedComponent {...allProps} />
    }
  }
}

/**
 * withPropsFormatted()
 *
 * Add props to a Component, formatted by function
 *
 * // <Enhanced /> will have class "enhanced"
 * // unless overwritten like <Enhanced className="overwritten" />
 * const Enhanced = withProps(
 *   Component,
 *   ({ data: className }) => ({ className }),
 *   { data: "enhanced" }
 * )
 */
const withPropsFormatted = function(
  WrappedComponent,
  formatter,
  ...defaultProps
) {
  return class extends React.Component {
    render() {
      let allProps = Object.assign({}, ...defaultProps, this.props)

      if (typeof formatter === "function") {
        allProps = formatter(allProps)
      }

      return <WrappedComponent {...allProps} />
    }
  }
}

/**
 * Exports
 */
export default function(...args) {
  return typeof args[1] !== "function" || args[1] === null
    ? withPropsShorthand(...args)
    : withPropsFormatted(...args)
}
