// @flow

/**
 * Imports
 */

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Slideshow />
type Props = {
  /** Automatically play animation */
  autoPlay: boolean,
  /** Loop the animation */
  loop: boolean,
  /** Animation interval (seconds) */
  interval: number,
  /** Animation delay before playing (seconds) */
  delay: number,
  /** Slideshow direction */
  direction: "ltr" | "rtl",
  /** Root element class name */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

type State = {
  currentSlideIndex: number,
  slides: Array<React.Node>,
  paused: boolean,
}

/**
 * Create a slideshow effect with CSS animations.
 *
 * This component simply takes all provided children and gives them CSS classes
 * that can be used to create a slideshow effect.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .slideshow       | Root element                                           |
 * | .slide           | Slide element                                          |
 * | .active          | Present on current slide element                       |
 * | .in              | Present on slide element if it is animating in         |
 * | .out             | Present on slide element if it is animating out        |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/slideshow.scss
 * - create a similar effect with GSAP animations with `./slideshow-gsap.js`
 */
class Slideshow extends React.Component<Props, State> {
  static defaultProps = {
    autoPlay: false,
    loop: false,
    interval: 3,
    delay: 0,
    direction: "ltr",
  }

  state = {
    currentSlideIndex: 0,
    slides: [],
    paused: true,
  }

  static Slide: Class<Slide>

  // component props
  timeouts: Array<TimeoutID> = []
  intervals: Array<IntervalID> = []

  // refs
  self: React.Element<Slideshow>
  slideRefs: Object

  // custom methods

  /** Play slideshow animation */
  play() {
    const { interval, delay } = this.props

    // handle delay
    this.timeouts.push(
      setTimeout(() => {
        // remember that we are playing the animation
        this.setState({ paused: false })

        // set the interval
        this.intervals.push(
          setInterval(() => {
            this.next()
          }, interval * 1000)
        )
      }, delay * 1000)
    )
  }

  /** Pause slideshow animation */
  pause() {
    // clear all timeouts/intervals
    this.timeouts.forEach(id => clearTimeout(id))
    this.intervals.forEach(id => clearInterval(id))

    this.timeouts = []
    this.intervals = []

    // remember that we are paused
    this.setState({ paused: true })
  }

  /** Go to next slide */
  next(direction: "ltr" | "rtl" = "ltr", resetInterval: boolean = false) {
    const { slides, currentSlideIndex, paused } = this.state
    const { loop } = this.props

    // get next slide index
    const angle = { rtl: -1, ltr: 1 }[direction]

    const lastIndex = slides.length - 1 * angle
    let nextIndex = currentSlideIndex + 1

    // wrap around
    if (nextIndex > lastIndex) nextIndex = 0
    if (nextIndex < 0) nextIndex = lastIndex

    // go to slide
    this.goTo(nextIndex)

    // prevent looping if disabled
    if (nextIndex === lastIndex && !loop) {
      this.pause()
    }

    // reset interval if not paused
    // useful if a user interaction advances an autoPlaying slideshow
    if (resetInterval && !paused) {
      this.pause()
      this.play()
    }
  }

  /** Go to previous slide */
  prev(direction: "ltr" | "rtl" = "ltr", resetInterval: boolean = false) {
    const oppositeDirection = { ltr: "rtl", rtl: "ltr" }[direction]
    this.next(oppositeDirection, resetInterval)
  }

  /** Go to specific slide index */
  goTo(index: number) {
    const { currentSlideIndex } = this.state

    const currentSlide = this.slideRefs[currentSlideIndex].current
    const nextSlide = this.slideRefs[index].current

    currentSlide.setState({ active: false, movement: "out" })
    nextSlide.setState({ active: true, movement: "in" })

    this.setState({ currentSlideIndex: index })
  }

  // react methods
  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).play = this.play.bind(this)
    ;(this: any).pause = this.pause.bind(this)
    ;(this: any).next = this.next.bind(this)
    ;(this: any).prev = this.prev.bind(this)
    ;(this: any).goTo = this.goTo.bind(this)
  }

  componentDidMount() {
    let { children, autoPlay } = this.props

    // reset slide refs
    this.slideRefs = {}

    // create slides
    const slides = React.Children.toArray(children)
      .filter(slide => typeof slide === "object")
      .map((component, i) => {
        // each slide needs a ref, so we can set its state
        const ref = React.createRef()
        this.slideRefs[i] = ref

        return (
          <Slide key={i} ref={ref}>
            {component}
          </Slide>
        )
      })

    // update slideshow
    this.setState({ slides })

    // autoPlay if enabled
    if (autoPlay) {
      this.play()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { slides: prevSlides } = prevState
    const { slides, currentSlideIndex } = this.state

    // set slide active states
    if (slides !== prevSlides) {
      Object.values(this.slideRefs).forEach((slideRef: Object, i) => {
        // get slide component
        const slideComponent = slideRef.current
        if (!slideComponent) return

        // set state
        slideComponent.setState({ active: i === currentSlideIndex })
      })
    }
  }

  componentWillUnmount() {
    // we're using timeouts/intervals
    // so pausing is the same as clearing/killing
    this.pause()
  }

  render() {
    const {
      // element props
      autoPlay,
      loop,
      interval,
      delay,
      direction,
      // class names
      className,
      // content
      children,
      // passthru
      ...slideshowProps
    } = this.props

    return (
      <div
        ref={c => (this.self = c)}
        {...classNames("slideshow").plus(className)}
        {...slideshowProps}
      >
        {this.state.slides}
      </div>
    )
  }
}

/**
 * Slide element
 */
class Slide extends React.Component<
  {
    className?: string,
    children: React.Node,
  },
  {
    active: number,
    movement: "in" | "out" | "",
  }
> {
  state = {
    active: false,
    movement: "",
  }

  // react methods

  render() {
    const { className, children, ...slideProps } = this.props
    const { active, movement } = this.state

    return (
      <div
        {...classNames("slide")
          .plus(active ? "active" : "")
          .plus(movement)
          .plus(className)}
        {...slideProps}
      >
        {children}
      </div>
    )
  }
}

/**
 * Exports
 */
Slideshow.Slide = Slide

export default Slideshow
