// config
import { BREAKPOINTS } from "../../client/config"

// utility
import isSSR from "../utility/is-ssr"
import getCrossBrowserWindowValues from "./get-cross-browser-window-values"

/**
 * Test if browser is mobile
 */
function isMobile() {
  // ssr defaults to not mobile
  if (isSSR()) return false

  const { size } = getCrossBrowserWindowValues()
  return size.width < BREAKPOINTS.MOBILE
}

export default isMobile
