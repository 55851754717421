// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Button />
type Props = {
  /** Custom class for root element */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

type State = {
  /** Active: true if button is active */
  active: boolean,
}

/**
 * Generic button element.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .button          | Root element                                           |
 * | .active          | Present on root element when state.active = true       |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/button.scss
 * - find button @mixin at /app/core/styles/mixins/_field.scss
 */
class Button extends React.Component<Props, State> {
  state = {
    active: false,
  }

  // react methods

  render() {
    const { className, children, ...passthruProps } = this.props
    const { active } = this.state

    return (
      <button
        {...classNames("button")
          .plus(className)
          .plus(active ? "active" : "")}
        {...passthruProps}
      >
        {children}
      </button>
    )
  }
}

/**
 * Exports
 */
export default Button
