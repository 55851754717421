// @flow

// react
import * as React from "react"
import Helmet from "react-helmet"

// <SEO />
type Props = {
  title: string,
  description: string,
  lang: string,
  data: {
    page: {
      title?: string,
      description?: string,
      lang?: string,
    },
    site: {
      title: string,
      description: string,
      lang: string,
    },
    metadata: Array<{
      name: string,
      content: string,
    }>,
  },
}

/**
 * Handle page title, description, and meta content.
 *
 * ## See Also
 * - [React Helmet](https://github.com/nfl/react-helmet)
 */
class SEO extends React.Component<Props> {
  static defaultProps = {
    data: {
      site: {},
      page: {},
      metadata: [],
    },
  }

  // react methods

  render() {
    let { title, description, lang } = this.props
    let { page, site, metadata } = this.props.data

    if (!page) page = {}
    if (!site) site = {}
    if (!metadata) metadata = {}

    let pageTitle = title || page.title || site.title
    let pageDescription = description || page.description || site.description
    let pageLang = lang || page.lang || site.lang || "en"

    const meta = [
      {
        name: `description`,
        content: pageDescription,
      },
      {
        property: `og:title`,
        content: pageTitle,
      },
      {
        property: `og:description`,
        content: pageDescription,
      },
      {
        property: `og:type`,
        content: `websiteMetadata`,
      },
    ].concat(metadata)

    let docTitle = site.title
    if (pageTitle && pageTitle !== "home" && pageTitle !== site.title) {
      docTitle = `${pageTitle} | ${site.title}`
    }

    return (
      <Helmet
        htmlAttributes={{
          lang: pageLang,
        }}
        title={docTitle}
        meta={meta}
      />
    )
  }
}

/**
 * Exports
 */
export default SEO
